import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDateFormat } from '@front/helper';
import { compactNumberFormat, currencyFormat } from '@front/ui';
import {
  CardLayoutItem,
  CardLayoutItemEvent,
  CardLayoutItemStatus,
} from '@lib/ia/src/layouts/CardLayout/types';
import {
  ClubJoinedStatus,
  ClubOfficialType,
  ClubPrivacy,
  ClubStatus,
  ClubVisibility,
} from '@lib/web/apis';
import { getClubIconPath } from '@lib/web/utils';

import { getIaClubCta, IaClubCtaAction } from './iaClubCta';
import useIaClubTags from './useIaClubTags';

interface ClubItem {
  clubId: string;
  clubLogo: string;
  clubName: string;
  clubSlug: string;
  shortDescription: string;
  clubCreator: GetIaClubCreatorRes;
  joinStatus: ClubJoinedStatus;
  totalQuestionCount: number;
  onlineMemberCount: number;
  clubMemberCount: number;
  clubStatus: ClubStatus;
  privacy: ClubPrivacy;
  visibility: ClubVisibility;
  officialType: ClubOfficialType;
  createdAt: string;
}

export default function useIaClubCardItem() {
  const { t } = useTranslation('club');
  const { getIaClubTags } = useIaClubTags();
  const { dateFormat } = useDateFormat();

  return useMemo(
    () => ({
      mapToCardItem: (item: ClubItem): CardLayoutItem => {
        const disabled = item.clubStatus === ClubStatus.Inactive;
        const validAppIconPath =
          item.clubLogo || getClubIconPath(item.clubName, disabled);

        const tags = getIaClubTags(item);
        const events: CardLayoutItemEvent[] = [
          {
            type: 'link' as const,
            value: `/club/${item.clubSlug}`,
            icon: 'ActionArrowRightUp',
            text: 'Open Details',
          },
          getIaClubCta(item.clubSlug, item.joinStatus, item.privacy),
        ];

        const isOfficial = item.officialType === ClubOfficialType.Official;

        return {
          id: item.clubSlug,
          avatar: { src: validAppIconPath },
          title: item.clubName,
          cornerAvatar:
            item.clubCreator.clubCreatorName !== 'Aha'
              ? item.clubCreator.clubCreatorAvatar
              : '/logo.png',
          cornerAvatarText: item.clubCreator.clubCreatorName,
          titleSuffixIcon: isOfficial ? 'ProfileOfficial' : undefined,
          description: item.shortDescription,
          tags,
          properties: [
            [
              {
                text: t('## Member', { count: item.clubMemberCount }),
                icon: 'PrivacyFriends',
              },
              {
                text: `${compactNumberFormat(item.onlineMemberCount)} Online`,
                icon: 'UserOnline',
              },
            ],
            {
              text: `${currencyFormat(item.totalQuestionCount)} Questions`,
              icon: 'ChatQuestionList',
            },
          ],
          action: {
            type: 'link' as const,
            value: `/club/${item.clubSlug}`,
          },
          status: disabled
            ? CardLayoutItemStatus.Disabled
            : CardLayoutItemStatus.Enabled,
          events: disabled ? undefined : events,
          metadata: {
            creatorId:
              item.clubCreator.clubCreatorName !== 'Aha'
                ? item.clubCreator.clubCreatorSourceId
                : undefined,
            clubLogo: validAppIconPath,
            clubName: item.clubName,
          },
          tooltip: {
            type: 'list',
            config: {
              title: {
                content: item.clubName,
                prefix: {
                  type: 'avatar',
                  avatarUrl: validAppIconPath,
                  avatarText: item.clubName,
                },
                suffix: isOfficial
                  ? {
                      type: 'icon',
                      name: 'ProfileOfficial',
                    }
                  : undefined,
              },
              properties: [
                {
                  name: {
                    icon: 'PropertyTypePerson',
                    text: 'Club Creator',
                  },
                  value: {
                    type: 'avatarText',
                    avatarText: item.clubCreator.clubCreatorName,
                    avatarUrl:
                      item.clubCreator.clubCreatorName !== 'Aha'
                        ? item.clubCreator.clubCreatorAvatar
                        : '/logo.png',
                  },
                },
                {
                  name: {
                    icon: 'PrivacyFriends',
                    text: 'Members',
                  },
                  value: {
                    type: 'text',
                    text: currencyFormat(item.clubMemberCount),
                  },
                },
                {
                  name: {
                    icon: 'UserOnline',
                    text: 'Online',
                    sxProps: {
                      '& svg path': {
                        fill: '#00C398',
                      },
                    },
                  },
                  value: {
                    type: 'text',
                    text: currencyFormat(item.onlineMemberCount),
                  },
                },
                {
                  name: {
                    icon: 'ChatQuestionList',
                    text: 'Questions',
                  },
                  value: {
                    type: 'text',
                    text: currencyFormat(item.totalQuestionCount),
                  },
                },
                {
                  name: {
                    icon: 'OtherCalendarDay',
                    text: 'Club Created',
                  },
                  value: {
                    type: 'text',
                    text: dateFormat(item.createdAt),
                  },
                },
              ],
            },
          },
          cornerAvatarEventMap: {
            onMouseEnter: {
              value: IaClubCtaAction.CornerAvatarMouseEnter,
            },
            onMouseLeave: {
              value: IaClubCtaAction.CornerAvatarMouseLeave,
            },
            onBlur: {
              value: IaClubCtaAction.CornerAvatarBlur,
            },
          },
        };
      },

      getEmptyAction: (
        state?: 'default' | 'loading' | 'error'
      ): CardLayoutItem => {
        return {
          id: 'action',
          type: 'action',
          title: t('button.Create New Club', 'Create New Club'),
          action: {
            type: 'link',
            value: '/club/form/overview',
          },
          status:
            state === 'loading' ? CardLayoutItemStatus.Disabled : undefined,
          tooltip: {
            type: 'simple',
            config: {
              content: t(
                'button.create.tooltip',
                'Click to create your own bot'
              ),
            },
          },
          actionSettings: {
            state: state,
            errorMessage: t(
              'button.create.error',
              'Unable to create new club. Please refresh the page or try again later.'
            ),
            errorActions: [
              {
                type: 'event',
                value: IaClubCtaAction.CreationErrorDismiss,
                text: 'Dismiss',
                icon: 'ActionClose',
              },
              {
                type: 'link',
                value: '/club/form/overview',
                text: 'Retry',
                icon: 'OtherResend',
              },
            ],
          },
        };
      },
    }),
    [dateFormat, getIaClubTags, t]
  );
}
